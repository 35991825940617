* {
  font-family: "Gabriola", cursive;
  color: gold;
  text-shadow: 0 0 7px gold;
}

html {
  overflow-y: scroll;
}

body {
  background-color: black;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.ai-center {
  align-items: center;
}

.arrow-link {
  font-size: 1.5em;
  padding: 0em 1em;
  margin: 0em 0.5em 0.25em 0em;
  display: block;
  padding: 1.25em, 2em;
  text-align: center;
  text-decoration: none;
  border-style: double;
  border-width: 5px;
  border-color: #1be0e5;
  box-shadow: 0 0 7px #1be0e5;
}

.arrow-link:hover {
  box-shadow: 0 0 7px #1be0e5, 0 0 10px #1be0e5, 0 0 12px #1be0e5;
  text-shadow: 0 0 7px gold, 0 0 10px gold, 0 0 13px gold, 0 0 16px gold;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.page-title {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  font-size: 3em;
  line-height: 1em;
  height: 1.25em;
}

.link-style {
  display: block;
  padding: 1.25em, 2em;
  text-align: center;
  text-decoration: none;
  border-style: solid;
  border-color: #1be0e5;
  box-shadow: 0 0 7px #1be0e5;
}

.link-style:hover {
  box-shadow: 0 0 7px #1be0e5, 0 0 10px #1be0e5, 0 0 12px #1be0e5;
  text-shadow: 0 0 7px gold, 0 0 10px gold, 0 0 13px gold, 0 0 16px gold;
}

.icon {
  width: 1.5em;
  height: 1.5em;
}

.jc-space-around {
  justify-content: space-around;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-center {
  justify-content: center;
}

.navlink {
  margin: 4px;
  font-size: 2em;
}

.navlink:hover {
  text-shadow: 0 0 7px gold, 0 0 10px gold, 0 0 13px gold, 0 0 16px gold;
}

.proj-name {
  font-size: 1.5em;
  padding: 0em 1em;
  margin: 0em 0.5em 0.25em 0em;
}

#banner {
  margin: 0 2em 0 2em;
  border-bottom: solid;
  border-color: gold;
}

#contentbody {
  height: 100vh;
  padding: 0 2em;
}

#ghimg {
  border-style: none;
  border-radius: 50%;
  margin-left: 0em 0em 0em 0.25em;
}

#ghimg:hover {
  box-shadow: 0 0 7px white, 0 0 10px white, 0 0 10px white;
}

#linkedin {
  border-style: none;
  border-radius: 15%;
}

#linkedin:hover {
  box-shadow: 0 0 7px white, 0 0 10px white, 0 0 10px white;
}

#navarrows {
  padding: 1em;
}

#navlinks {
  margin: 4px;
}

#title {
  font-size: 4em;
  margin-right: 0.25em;
}

#title:hover {
  text-shadow: 0 0 7px gold, 0 0 10px gold, 0 0 13px gold;
}
